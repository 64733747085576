import Box from "../components/Box";
import BoxWithGutter from "../components/BoxWithGutter";
import { DARK } from "../core/constants";
import HeadingLarge from "../components/HeadingLarge";
import Layout from "../components/Layout";
import LinkButtonPrimary from "../components/LinkButtonPrimary";
import PageContent from "../components/PageContent";
import PropTypes from "prop-types";
import Text from "../components/Text";
import { getFooter } from "../core/api/core";
import { getMenu } from "../core/api/menu";

export default function Custom404({ menuData, globalData }) {
  return (
    <Layout navItems={menuData} globalData={globalData}>
      <PageContent>
        <BoxWithGutter
          pt={60}
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Text
            as="h1"
            uppercase
            fontSize={[42, 64, 82, 82]}
            lineHeight={1}
            fontWeight={700}
            textAlign="center"
          >
            404
          </Text>
          <Box my={[30, null, 40, null]}>
            <HeadingLarge textAlign="center" uppercase>
              Oops! Page not found
            </HeadingLarge>
          </Box>
          <LinkButtonPrimary theme={DARK} href="/">
            Home
          </LinkButtonPrimary>
        </BoxWithGutter>
      </PageContent>
    </Layout>
  );
}

Custom404.propTypes = {
  menuData: PropTypes.array.isRequired,
};

export async function getStaticProps() {
  const menuData = await getMenu();
  const globalData = await getFooter();

  return {
    props: {
      menuData,
      globalData,
    },
    revalidate: 300,
  };
}
